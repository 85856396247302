import $ from 'jquery'; 

export default function nav() {

  // Change styles of menu after first section
  // =========================================

  var topofDiv = $(".slide-section").offset().top; //gets offset of header
  var height = $(".slide-section").outerHeight(); //gets height of header

  $(window).scroll(function(){
      if($(window).scrollTop() > (topofDiv + height)){
         $("#menu").addClass('active');
      }
      else{
         $("#menu").removeClass('active');
      }
  });

  // Smooth Scroll Anchor
  // ====================

  var $root = $('html, body'),
      $body = $('body');

  $('a.scroll').click(function() {

      if($body.css('overflow') == 'hidden') {
        $.fn.fullpage.setAutoScrolling(false);
      } 

      $root.animate({
          scrollTop: $( $.attr(this, 'href') ).offset().top -100
      }, 500);
      return false;
  });

  // Mobile Hamburger menu animation
  // ===============================

  $('.tcon').click(function(event) {
    event.preventDefault();
    $(this).toggleClass('tcon-transform');

    $('.menu-options').toggleClass('show');
  });

  $('.menu-options a, a.logo-container').click(function(event) {
    $('.tcon').removeClass('tcon-transform');
    $('.menu-options').removeClass('show');
  });

}


