/**
 * @file This file defines all client-side routing and transitioning logic.
 */
'use strict';


// Imported as an external dependency (you need to include it on the page)
import $ from 'jquery'; 
// import 'velocity';
// import 'velocity/velocity.ui';
// import 'fontspy';

/* Custom tasks - split out for organization */
import bindUI from './tasks/bindUI';
import sectionSlide from './tasks/sectionSlide';
import nav from './tasks/nav';

/* Page auto-routing rules and transitions */
import pm from './routes';
pm.startRouting();

/**
 * We attach these as separate functions so they can be called again
 * during AJAX loads 
 */
// Document has been loaded
$(documentLoaded);
// All media has been loaded
$(window).on('load', windowLoaded);

export var $body;

export function windowLoaded() {
    
}

// The reason we need to unbind and re-bind events is for AJAX loading.
// documentLoaded() will be called again after the page is imported with Page.js


export function documentLoaded() {
  $body = $('body');
  
  sectionSlide();
  nav();
  bindUI();
  
  // var $fpSlidesNav = $('.fp-slidesNav'),
  //     $slide1 = $('#slide1'),
  //     $hasNav = $('.has-nav'),
  //     $slideSection = $('.slide-section');


  // if ( $slide1.hasClass('active') ) {
  //   $fpSlidesNav.addClass('hide');
  // }
  // if ( $hasNav.hasClass('active') ){
  //     console.log('hello');
  //     $slideSection.find('.fp-slidesNav').removeClass('hide');
  // }
}


window.addEventListener('DOMContentLoaded', function() {
  QueryLoader2(document.querySelector("body"), {
      barColor: "#efefef",
      backgroundColor: "#006393",
      percentage: true,
      barHeight: 1,
      minimumTime: 800,
      onComplete: function() {

        setTimeout(function() {
          $('.fp-slidesNav').addClass('show');
          $('.scroll-indicator').addClass('active');
          $('.circle').addClass('active');
        }, 100);

       

      },
      fadeOutTime: 1500
  });
});